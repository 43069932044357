import { Card, CardContent, CardHeader, Box, Button, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { textAlign } from '@mui/system';
import Moment from 'moment';
import { useNavigate } from 'react-router-dom';


export default function StatusCard ({status}) {

    const navigate = useNavigate();

    return <Card
    
    sx={{
        borderRadius: 8,
        boxShadow: "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px"
      }}
    >
        <CardHeader sx={{textAlign: "left", marginLeft: 2, marginTop: 2, color: (theme) => theme.palette.text.secondary}} title={"CashEx SEVIS Payment Service Order Status"}
        subheader={"Please save this page for your records"}>
       
       </CardHeader>
        <CardContent>
            <Box  display="flex" m={2} justifyContent="center" alignItems="center">
                <Box sx={{width: {xs: "100%", md: "75%", lg: "50%"}}}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                Name
                            </TableCell>
                            <TableCell>
                            {status.first_name} {status.last_name}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Email
                            </TableCell>
                            <TableCell>
                            {status.email}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Phone
                            </TableCell>
                            <TableCell>
                            {status.phone}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Reference #
                            </TableCell>
                            <TableCell>
                            {status.id}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Submitted Time
                            </TableCell>
                            <TableCell>
                            {status.timestamp ? Moment.utc(status.timestamp).local().format("LLL") : ""}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Status
                            </TableCell>
                            <TableCell>
                            {status.status}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Paid
                            </TableCell>
                            <TableCell>
                            {status.paid ? "Yes": <Button
                            onClick={() => navigate('/payment/' + status.id)}
                             variant={"contained"}>
                                Pay Now</Button>}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                Paid Time
                            </TableCell>
                            <TableCell>
                            {status.paid_time ? Moment.utc(status.paid_time).local().format("LLL") : ""}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                </Box>
                
               
            </Box>
        </CardContent>
    </Card>
}