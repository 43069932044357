import React, { useState } from 'react';
import logo from './../logo.svg';
import './../App.css';
import CashExAppBar from './../components/CashExAppBar';
import { AppBar, Box, Button, Card, CardContent, CardHeader, IconButton, InputAdornment, TextField, Toolbar, Typography, Skeleton } from '@mui/material';
import { Container } from '@mui/system';
import { ArrowForward, PhotoCamera, UploadFile } from '@mui/icons-material';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import APIService from './APIService';

function IntakeForm() {

  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState();
  const [fileError, setFileError] = useState(false);
  const [firstName, setFirstName] = useState();
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastName, setLastName] = useState();
  const [lastNameError, setLastNameError] = useState(false);
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState(false);
  const [phone, setPhone] = useState();
  const [phoneError, setPhoneError] = useState(false);
  const navigate = useNavigate();

  const submitForm = () => {
    let isError = false;
    if (!firstName) {
      setFirstNameError(true);
      isError = true;
    }
    if (!lastName) {
      setLastNameError(true);
      isError = true;
    }
    if (!email) {
      setEmailError(true);
      isError = true;
    }
    if (!phone) {
      setPhoneError(true);
      isError = true;
    }
    if (!fileName) {
      setFileError(true);
      isError = true;
    }
    if (!isError) {
      const formData = new FormData();
      formData.append('File', file);
      formData.append('first_name', firstName)
      formData.append('last_name', lastName)
      formData.append('phone', phone)
      formData.append('email', email)
      setIsLoading(true);
      new APIService().postForm("intake_form", formData)
        .then((response) => response.json())
        .then((result) => {
          navigate("/payment/" + result.id);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error('Error:', error);
        }).finally( () => setIsLoading(false)
        );

    }

  }


  return (
    <Container>
      <Box
        m={0}></Box>
      {isLoading ? <Skeleton variant="rectangular" height={400} /> : <Card
      sx={{
        borderRadius: 8,
        boxShadow: "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px"
      }}
      >
        <CardContent>
          <img height={100} src="/sevis.png"></img>
          <Box m={2} alignContent={"center"}>
            <Typography variant={"h6"}>
              CashEx SEVIS Payment Service
            </Typography>
            <Box sx={{width: {xs: "100%", md: "50%"}, marginLeft: {xs: 0, md: "25%"}}}>
            <Typography variant={"caption"} sx={{textAlign: "left"}}>
              Please fill out the information below so CashEx can handle paying your SEVIS fee here in the US. 
              After you fill out this form, you have the option of paying us in Naira. We will be in touch with you 
              every step of the way to provide you with the best possible service. <br/><br/> The total charge is $375 USD ($350 SEVIS fee and a $25 service fee).
            </Typography>
            </Box>
           
          </Box>

          <Box

            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '75%', maxWidth: '50ch' },
              //textAlign: "left",
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <TextField
                id="first-name"
                label="First Name"
                required
                value={firstName}
                onChange={(e) => { setFirstName(e.target.value) }}
                error={firstNameError}
                helperText={firstNameError && "Required"}
              />
            </div>
            <div>
              <TextField
                id="last-name"
                label="Last Name"
                required
                error={lastNameError}
                onChange={(e) => { setLastName(e.target.value) }}
                helperText={lastNameError && "Required"}
              />
            </div>

            <div>
              <TextField
                id="email"
                label="Email"
                required
                error={emailError}
                onChange={(e) => { setEmail(e.target.value) }}
                helperText={emailError && "Required"}
              />
            </div>

            <div>
              <TextField
                id="phone-number"
                label="Phone Number"
                required
                error={phoneError}
                onChange={(e) => { setPhone(e.target.value) }}
                helperText={phoneError && "Required"}
              />
            </div>
            <div>
              <TextField
                id="file-name"
                label="I-901 Application Payment Coupon"
                required
                value={fileName}
                error={fileError}
                helperText={fileError && "Required"}
                InputProps={{
                  startAdornment: <InputAdornment position="start">
                    <IconButton
                      color={"primary"}
                      variant="text"
                      component="label"
                    >
                      <UploadFile />
                      <input
                        accept="application/pdf"
                        onChange={(event) => {
                          setFileName(event.target.files.item(0).name)
                          setFile(event.target.files.item(0))
                        }}
                        type="file"
                        hidden
                      />
                    </IconButton>
                  </InputAdornment>
                }}
                
              />
              
            </div>
           <div>
            <Box m={2}>
            <Button endIcon={<ArrowForward/>} variant={"contained"} sx={{width: "200px"}} onClick={submitForm}>
                NEXT
              </Button>
            </Box>
          
           </div>
          </Box>
        </CardContent>
      </Card>}
    </Container>
  );
}

export default IntakeForm;
