import * as React from 'react';
import {Box} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQAccordion() {
  return (
    <div>
      <Accordion
      sx={{
        boxShadow: "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px"
      }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography><b>What is CashEx?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography textAlign={"left"}>
            CashEx is a venture-backed fintech startup  building the future of digital finance for Africans and African migrants.
            CEO Kingsley Ezeani personally experienced the pain of paying the SEVIS fee when he came from Nigeria to study at Harvard. 
            He is committed to building better financial services for Africans and African migrants.
          </Typography>
          <Box m={2}></Box>
          <Typography textAlign={"left"}>
            Learn more about Kingsley and the rest of the CashEx team on <a target="_blank" href="https://www.linkedin.com/company/getcashex">LinkedIn</a>. 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
      sx={{
        boxShadow: "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px"
      }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography><b>How does the SEVIS payment service work?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography textAlign={"left"}>
            The SEVIS payment service works as easy as 1, 2, 3. 
            <Box m={1}/>
            1. Fill out the SEVIS Payment Service Intake Form
            <Box m={1}/>
            2. Pay the SEVIS fee and a small service fee. See "How much does it cost and how do I pay?" for more information.
            <Box m={1}/>
            3. CashEx will pay your SEVIS fee in the US and keep you updated on the status. We anticipate 5-10 business days to complete the process. You can contact us any time throughout the process (See "How can I contact CashEx?")
            <Box m={1}/>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
      sx={{
        boxShadow: "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px"
      }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography><b>How much does it cost and how do I pay?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography textAlign={"left"}>
           The SEVIS service fee is 350 USD. We add a 25 USD service fee for handling your payment. We charge you in Naira, with the total amount based on current exchange rates. For example, if the rate is 1 USD = 600 NGN, 
           the total fee will be 225,000 NGN. We use Paystack to charge your Naira bank account. You can be sure your money is secure.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
      sx={{
        boxShadow: "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px"
      }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography><b>How can I contact CashEx?</b></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography textAlign={"left"}>
            You can email hello@cashex.app at any time. We look forward to hearing from you!
          </Typography>
        </AccordionDetails>
      </Accordion>
      
    </div>
  );
}