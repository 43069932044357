import React, { useState, useEffect } from 'react';
import { Box, Button, Card, CardContent, Container, Skeleton } from "@mui/material";
import PaystackPop from '@paystack/inline-js';
import { useParams } from 'react-router-dom';
import StatusCard from './StatusCard';
import APIService from './APIService';



export default function StatusPage() {

    let { id } = useParams();
    const [data, setData] = React.useState({})
    const [prices, setPrices] = React.useState({})

    useEffect(() => {
        new APIService().post(
            'get_intake_form',
            {form_id: id}
        ).then((response) => response.json())
            .then((result) => {
                setData(result)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [useParams])

    useEffect(() => {
        new APIService().post( 'get_price',
        {form_id: id})
            .then((response) => response.json())
            .then((result) => {
                setPrices(result)
                console.log(result);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }, [useParams])


    return <Container>
        <Box m={4}></Box>
        {data.id ? <StatusCard status={data}/> : <Skeleton height={600}></Skeleton>}
    </Container>

}
