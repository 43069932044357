import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import CashExAppBar from './components/CashExAppBar';
import { AppBar, Box, Button, Card, CardContent, IconButton, InputAdornment, MenuItem, TextField, Toolbar, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { PhotoCamera, UploadFile } from '@mui/icons-material';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import IntakeForm from './components/IntakeForm';
import PaymentPage from './components/PaymentPage';
import StatusPage from './components/StatusPage';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useNavigate } from 'react-router-dom';
import FAQAccordion from './components/FAQAccordion';


const theme = createTheme({
  palette: {
    primary: {
      main: '#3b43aa'
    },
    secondary: {
      main: '#57bdb7'
    }
  },
  typography: {
    fontFamily: [
      'Lato',
      'sans-serif'
    ].join(','),
  },
});

function App() {

  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <AppBar position="static" style={{ background: '#3b43aa' }}>
          <Toolbar>
            <MenuItem onClick={() => {navigate("/")}}>
            <img height={40} src={"/2.png"}>
              </img>
            </MenuItem>
           

          </Toolbar>
        </AppBar>


        <Box style={{ height: 50 }}>

        </Box>
        <Container>
          <Routes>
            <Route path="/" element={<IntakeForm />}></Route>
            <Route path="/payment/:id" element={<PaymentPage />}></Route>
            <Route path="/status/:id" element={<StatusPage />}></Route>
          </Routes>
        </Container>

        <Box style={{ height: 100 }}>
        </Box>

        
        <Container>

          <Box m={4}>
            <Box textAlign={"left"}>
            <Typography variant={"h6"}>Frequently Asked Questions</Typography>
            </Box>
           
            <Box m={2}></Box>
          <FAQAccordion></FAQAccordion>
          </Box>
        
        </Container>
        

      </div>

    </ThemeProvider>


  );
}

export default App;
