

class APIService {

    BASE_URL = "https://us-central1-cashex-sevis.cloudfunctions.net/cashex-sevis-backend"

    post(url_path, body_data) {
        const url = this.BASE_URL
        return fetch(
            url,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                body: JSON.stringify({
                    operation: url_path,
                    params: body_data
                }),
            }
        )
    }
    postForm(formName, form_data) {
        const url = this.BASE_URL + "/form?form_name=" + formName
        return fetch(
            url,
            {
                method: 'POST',
                body: form_data,
            }
        )
    }
    get(url_path) {
        const url = this.BASE_URL + url_path
        return fetch(
            url,
            {
                method: 'GET',
            }
        )
    }

}

export default APIService;

