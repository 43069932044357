import React, { useState, useEffect } from 'react';
import { Box, Button, Card, CardContent, Container, Skeleton } from "@mui/material";
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import StatusCard from './StatusCard';
import PaymentCard from './PaymentCard';
import APIService from './APIService';


export default function PaymentPage() {

    let { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(true);
    const [data, setData] = React.useState({})
    const [prices, setPrices] = React.useState({})

    useEffect(() => {
        new APIService().post('get_intake_form', {form_id: id})
            .then((response) => response.json())
            .then((result) => {
                setIsLoading(false);
                setData(result)
                if (result.paid) {
                    navigate("/status/" + id)
                }
            })
            .catch((error) => {
                console.error('Error:', error);
            }).finally(
                () => setIsLoading(false)
            );
    }, [useParams])

    const getPrices = (f) => {
        new APIService().post('get_price', {form_id: id})
            .then((response) => response.json())
            .then((result) => {
                setPrices(result)
                f()
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        getPrices(() => {})
    }, [useParams])

    return <Container>
        <Box m={4}></Box>
        {
            isLoading ? <Skeleton height={600}>

            </Skeleton> : data.id && prices.total && <PaymentCard status={data} prices={prices} getPrices={getPrices}></PaymentCard>
        }
    </Container>
}
