import { Button, Card, CardContent, CardHeader, Box, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PaystackPop from '@paystack/inline-js';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LockIcon from '@mui/icons-material/Lock';
import Paper from '@mui/material/Paper';
import APIService from './APIService';


export default function PaymentCard({ status, prices, getPrices }) {

    const navigate = useNavigate();
    const id = status.id;

    function payWithPaystack(e) {
        e.preventDefault();
        getPrices(() => {
            let handler = PaystackPop.setup({
                key: 'pk_live_480e47952b7b1a2d17d5ea20574a820434cee211', // Replace with your public key
                email: status.email,
                amount: prices["total"] * 100,
                channels: ['card', 'bank', 'ussd', 'qr', 'mobile_money', 'bank_transfer'],
                ref: id, // generates a pseudo-unique reference. Please replace with a reference you generated. Or remove the line entirely so our API will generate one for you
                // label: "Optional string that replaces customer email"
                onClose: function () {
    
                },
                callback: function (response) {
                    new APIService().post('intake_form_payment_success', {form_id: id})
                        .then((response) => response.json())
                        .then((result) => {
                            navigate('/status/' + id);
                        })
                        .catch((error) => {
                            console.error('Error:', error);
                        });
                }
            });
            handler.openIframe();
        })
       
    }

    return <Card
    sx={{
        borderRadius: 8,
        boxShadow: "rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px"
      }}
    >
        <CardHeader sx={{ textAlign: "left", marginLeft: 2, marginTop: 2, color: (theme) => theme.palette.text.secondary }} title={"Review and Pay"}>

        </CardHeader>
        <CardContent>
            <Box display="flex" m={2} justifyContent="center" alignItems="center">
                <Box sx={{ width: { xs: "100%", md: "75%", lg: "50%" } }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    Reference #
                                </TableCell>
                                <TableCell>
                                    {status.id}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    SEVIS Fee
                                </TableCell>
                                <TableCell>
                                    $ {prices.usd_price}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    CashEx Fee
                                </TableCell>
                                <TableCell>
                                    $ {prices.usd_fees}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Rate
                                </TableCell>
                                <TableCell>
                                    1 USD = NGN {prices.rate}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <b>Total</b>
                                </TableCell>
                                <TableCell>
                                    <b>NGN {prices.total && prices.total.toLocaleString()}</b>
                                </TableCell>
                            </TableRow>

                        </TableBody>
                    </Table>
                </Box>

            </Box>
            <Box m={4}>

            </Box>
            <Button startIcon={<LockIcon />} variant={"contained"} onClick={payWithPaystack}>
                Complete Payment
            </Button>
            <Box m={4}>
                Not ready to pay? Contact us at hello@cashex.app for more support.
            </Box>
        </CardContent>
    </Card>
}